const wrapWithContext = require('./wrapWithContext').default;


exports.onRouteUpdate = ({ location }) => {
  if (typeof window === 'undefined') {
    return;
  }

  let scrollPos = 0;

  // manually scroll to #refs
  // see https://github.com/gatsbyjs/gatsby/issues/8413

  if (location.hash) {
    const target = document.querySelector(location.hash);
    if (target) {
      scrollPos = Math.max(0, target.offsetTop - 40);
    }
  }
  
  return window.scrollTo(0, scrollPos);
}

exports.shouldUpdateScroll = () => false;

exports.wrapRootElement = wrapWithContext;
