import React from 'react';

export const AppContext = React.createContext();

const BLOGS_ENTRIES_PER_PAGE = 5;

const initialState = {
  searchText: '',
  searchResults: [],
  numPages: 0,
  pageNumber: 0,
  gclid: ''
}

class AppWrapper extends React.Component {
  constructor(props) {
    super();

    this.state = initialState;
  }
  componentDidMount() {
    let gclid = ''

    if (typeof window !== `undefined` && window.location && window.location.search) {
      const queryString = window.location.search

      if (typeof URLSearchParams !== `undefined`) {
        const urlParams = new URLSearchParams(queryString)

        gclid = urlParams.get('gclid')
      }
    }

    this.setState({ gclid })
  }
  render() {
    const { element } = this.props;

    return (
      <AppContext.Provider value={{
        ...this.state, 
        updateSearchData: ({searchText, searchResults}) => {
          const numPages = Math.ceil(searchResults.length / BLOGS_ENTRIES_PER_PAGE);

          this.setState({searchText, searchResults, numPages})
        },
        updatePageNumber: (pageNumber) => this.setState({pageNumber}),
        resetSearch: () => this.setState(initialState),
      }}>
        {element}
      </AppContext.Provider>
    )
  }
}

export default function({element}) {
  return (
    <AppWrapper element={element} />
  )
}
